<template>
    <div>
        <div>
            <div style="display: inline-block; float: right; cursor: pointer">
            <el-button-group class="card-toggle-table">
                <el-tooltip v-if="cardType" class="item" effect="dark" content="切换成表格" placement="top-start">
                    <el-button size="mini" plain icon="el-icon-s-grid" @click="toggle" />
                </el-tooltip>
                <el-tooltip v-else class="item" effect="dark" content="切换成卡片" placement="top-start">
                    <el-button size="mini" plain icon="el-icon-bank-card" @click="toggle" />
                </el-tooltip>
            </el-button-group>
        </div>
            <el-button @click="addRecipeButton">新增</el-button>
        </div>
        <!-- 如果cardType为true,则显示卡片风格 -->
        <el-row v-if="cardType">
            <el-col v-for="item in roles" :key="item.id" :span="5" style="margin-bottom: 10px" :offset="1">
                <el-card>
                    <div slot="header" class="clearfix">
                        <!-- <i class="el-icon-user" /> -->
                        <span style="margin-left: 5px">
                            {{ item.roleName }}
                        </span>
                        <div style="display: inline-block; float: right; cursor: pointer" @click="doEdit(item.id)">
                            <el-tooltip effect="dark" content="编辑角色" placement="top">
                                <i class="el-icon-edit-outline" style="margin-left: 15px" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div @dblclick="handleDoubleClick">
                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                        <ul class="role-info">
                            <li>
                                <div class="role-left">描述信息：{{ item.description }}</div>
                            </li>
                        </ul>
                    </div>
                    <div style="display: inline-block; float: left; cursor: pointer"
                        @click="doAssignPemission(item.id, item.roleName)">
                        <el-tooltip effect="dark" content="权限分配" placement="bottom">
                            <i class="el-icon-menu" />
                        </el-tooltip>
                    </div>
                    <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
                        <el-tooltip effect="dark" content="加入计划" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div>
                    <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
                        <el-tooltip effect="dark" content="查看分析" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div>
                    <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
                        <el-tooltip effect="dark" content="分享" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div>
                    <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
                        <el-tooltip effect="dark" content="删除角色" placement="bottom">
                            <i class="el-icon-delete" style="margin-left: 15px" />
                        </el-tooltip>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <!-- 如果cardType为false则显示列表风格 -->
        <el-table v-else ref="table" :data="roles" style="width: 100%; font-size: 12px"
            @selection-change="handleSelectionChange">
            <el-table-column :show-overflow-tooltip="true" width="150" prop="roleName" label="角色名称" />
            <!-- 
            <el-table-column :show-overflow-tooltip="true" prop="createTime" width="155" label="创建时间">
                <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="description" label="描述信息" />
            <el-table-column label="操作" width="300" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" round @click="doEdit(scope.row.id)">编辑角色</el-button>
                    <el-button size="mini" type="text" round @click="doDelete(scope.row.id)">删除角色</el-button>
                    <el-button size="mini" type="text" round
                        @click="doAssignPemission(scope.row.id, scope.row.roleName)">分配权限</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Recipde ref="recipdeModules"/>
    </div>
</template>
<script>
import Recipde from './recipde.vue'; 
export default {
    name: "name",
    components:{
        Recipde
    },
    data() {
        return {
            cardType: true,
            // 角色列表
            roles: [{
                description: "12"
            }, {
                description: "12"
            },{
                description: "12"
            }, {
                description: "12"
            },{
                description: "12"
            }, {
                description: "12"
            },{
                description: "12"
            }, {
                description: "12"
            },{
                description: "12"
            }, {
                description: "12"
            },],
        };
    },
    methods: {
        // 点击切换风格
        toggle() {
            this.cardType = !this.cardType;
        },
        //点击事件
        handleClick() {
            console.log('======')
        },
        //双击事件
        handleDoubleClick() {
            alert('您双击了这个div元素！');
        },
        handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      addRecipeButton(){
        this.$refs.recipdeModules.show('',1)
      }
    },
}

</script>